import React from 'react'
import Helmet from 'react-helmet'
import styles from './base.module.css'

import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import Chrome from '../../components/Chrome'

import screenshot from '../../../assets/feedback-portal-large.png'
import data from '../../../data/comparison.yaml'

export default class Page extends React.Component {
  renderItem = (item) => {
    return (
      <div className={styles.reason}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.us}>{item.us}</div>
        <div className={styles.them}>{item.them}</div>
      </div>
    )
  }

  render() {
    const dataItems = data.getsatisfaction

    return (
      <Layout>
        <div className={styles.root}>
          <Helmet title={'Get Satisfaction alternative - Suggested'}></Helmet>

          <div className={styles.intro}>
            <h1>Get Satisfaction alternative</h1>
            <h3>Try Suggested, designed for product people.</h3>
          </div>

          <div className={styles.lead}>
            <p>
              We think that Suggested fulfills a different role compared to Get
              Satisfaction. Suggested provides the tools needed to collect and
              manage user feedback to priorise product development; whereas Get
              Satisfaction is more about community management.
            </p>
            <p>
              We enable companies to build better products using feedback from
              their customers. With support for public roadmaps and changelogs
            </p>
          </div>

          <div className={styles.screenshot}>
            <Chrome>
              <img src={screenshot} alt="screenshot" />
            </Chrome>
          </div>

          <div className={styles.comparison}>
            <h1>Why our customers love Suggested</h1>
            <div className={styles.items}>
              {dataItems.map((item) => this.renderItem(item))}
            </div>
          </div>

          <div className="signup-container">
            <SignupButton />
          </div>
        </div>
      </Layout>
    )
  }
}
